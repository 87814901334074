@font-face {
  font-family: "Facundo";
  src: url("/fonts/facundo-extralight.woff");
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Facundo";
  src: url("/fonts/facundo-extralight.woff");
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Facundo";
  src: url("/fonts/facundo-light.woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Facundo";
  src: url("/fonts/facundo-regular.woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Facundo";
  src: url("/fonts/facundo-semi-bold.woff2");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Facundo";
  src: url("/fonts/facundo-black.woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Biennale";
  src: url("/fonts/Biennale-Regular.woff");
  font-weight: normal;
  font-style: 400;
  font-display: block;
}

@font-face {
  font-family: "Biennale";
  src: url("/fonts/Biennale-Medium.woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Biennale";
  src: url("/fonts/Biennale-SemiBold.woff");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Biennale";
  src: url("/fonts/Biennale-Bold.woff");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Century Old";
  src: url("/fonts/Century-Old-Style-Std-Regular-Italic.woff");
  font-weight: 400;
  font-style: italic;
  font-display: block;
}
