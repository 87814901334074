@import url("video.js/dist/video-js.css");

@layer global, reset, base, tokens, recipes, utilities;

@import url("./font-faces.css");

/* Added this here because the -webkit-font-smoothing property was not being applied through the panda config global styles */
body {
  -webkit-font-smoothing: antialiased;
}
